import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AuthProvider from "./components/AuthProvider/AuthProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { SocketProvider } from './context/SocketContext';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>

    <AuthProvider>
      <SocketProvider>
        <Router>
          <App />
        </Router>
      </SocketProvider>
    </AuthProvider>
  </React.StrictMode>
);
// SocketContext.jsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import socketio from "socket.io-client";
import { SERVER_URL } from '../constant';
import { useAuthContext } from "./AuthContext";


const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const { token } = useAuthContext();
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (token) {
            const newSocket = socketio.connect(SERVER_URL, {
                auth: {
                    strategy: 'jwt',
                    token: token,
                },
            });
            setSocket(newSocket);

            return () => {
                newSocket.disconnect();
            };
        }
    }, [token]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => useContext(SocketContext);


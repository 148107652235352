import React, { useEffect, useRef, useState } from 'react';
import { get_config_udg, get_config_gou, get_config_3min } from "./config.js";
import { on_update_udg_callback, on_update_3min_callback, on_update_gou_callback } from "./updateCharLogic.js";
import {
  Content, Row, Col, Inputs, Button,
} from 'adminlte-2-react';

import { Line, Bar } from "react-chartjs-2";
import { ButtonToolbar } from 'react-bootstrap';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import moment from 'moment';
import { CENTRIFUGE_URL, API } from "../../constant";
import { Interaction, LineController } from 'chart.js'
import Interpolate from './interpolate.js';
import zoomPlugin from "chartjs-plugin-zoom";
import { Centrifuge } from 'centrifuge'
import annotationPlugin from "chartjs-plugin-annotation";
import DeviationLog from '../../components/Modal/DeviationLog'
import UdgkLog from '../../components/Modal/UdgkLog'
import UdgLog from '../../components/Modal/UdgLog'
import ModalDialog from '../../components/Modal/ModalDialog'
import UdgkForm from '../../components/Modal/UdgkForm'
import UdgForm from '../../components/Modal/UdgForm'
import { ResizableBox } from 'react-resizable'
import "react-resizable/css/styles.css";
import { hasPermission } from "../../helpers";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { fetchWithAuth } from "./../../api/fetchWithAuth"

Chart.register(CategoryScale, zoomPlugin, annotationPlugin);

Number.prototype.twodig = function () {
  return Math.round(this * 100) / 100;
}

String.prototype.twodig = function () {
  return Math.round(this * 100) / 100;
}

Interaction.modes.interpolate = Interpolate
const getLabelAndValue = LineController.prototype.getLabelAndValue;
LineController.prototype.getLabelAndValue = function (index) {
  if (index === -1) {
    const meta = this.getMeta();
    const pt = meta._pt;
    const vScale = meta.vScale;
    const iScale = meta.iScale;

    return {
      label: iScale.getLabelForValue(iScale.getValueForPixel(pt.x)),
      value: vScale.getValueForPixel(pt.y).twodig()
    };
  }
  return getLabelAndValue.call(this, index);
}


// Plugin to draw the indicators
Chart.register({
  id: 'indicators',
  afterDraw(chart) {
    const metas = chart.getSortedVisibleDatasetMetas();
    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i];
      if (meta._pt) {
        meta._pt.draw(chart.ctx);
      }
    }
  },
  afterEvent(chart, args) {
    if (args.event.type === 'mouseout') {
      const metas = chart.getSortedVisibleDatasetMetas();
      for (let i = 0; i < metas.length; i++) {
        metas[i]._pt = null;
      }
      args.changed = true;
    }
  }
});

const {
  Date: Datepicker,
} = Inputs;


function Main(props) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [boxSize, setBoxSize] = useState({
    width: window.innerWidth - 300,
    height: window.innerHeight * 0.58,
  });

  const { permission, isLoading, setUser, logout } = useAuthContext();
  const navigate = useNavigate();
  let udgk = [], facts = [], pbrs = [], udg = [], rt3mins = [], rt3mins_labels = [];

  const [centrifuge, setCentrifuge] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const [state, setState] = useState({
    showResult: false,
    showModalDeviationLog: false,
    showModalUdgkLog: false,
    showModalUdgLog: false,
    showModalUdgkForm: false,
    showModalUdgForm: false,
    showConfirmDialogParent: false,
    title: "",
    temperatureValue: "н/д",
    pid: props.match.params.id,
    responseDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    requestDate: moment().format("YYYY-MM-DD"),
    date: moment().format("YYYY-MM-DD"),
    areaChartGou: {
      labels: [''],
      datasets: [
        {
          label: 'Факт',
          axis: 'y',
          data: [0],
          fill: false,
          borderColor: '#00770099',
          backgroundColor: '#00770099',
        },
        {
          label: '2',
          axis: 'y',
          data: [1000],
          fill: false,
          borderColor: '#8a8a8a33',
          backgroundColor: '#8a8a8a33',
        },
      ]
    },
    areaChart3MinOption: get_config_3min(90),
    areaChart3Min: {
      labels: rt3mins_labels,
      datasets: [
        {
          label: 'Факт',
          data: rt3mins,
          fill: true,
          xAxisID: 'x',
          tension: 0.5,
          borderColor: '#00000044',
          backgroundColor: '#00000044',
          hoverBackgroundColor: '#00000044',
          borderWidth: 0,
          categoryPercentage: 1.1,
          spanGaps: true
        }
      ],
    },
    chart: {
      labels: '',
      datasets: [
        {
          'label': 'УДГК',
          'data': udgk,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#336699',
          borderWidth: 2
        },
        {
          'label': 'УДГ',
          'data': udg,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#0099ff',
          borderWidth: 2
        },
        {
          'label': 'Факт',
          'data': facts,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#ff3300',
          borderWidth: 2,
          spanGaps: true
        },
        {
          'label': 'ПБР',
          'data': pbrs,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#ff9900',
          borderWidth: 2
        },
      ],
    }
  });

  const chartReference = useRef(null);
  const areaChart3MinReference = useRef(null);
  const areaChartGouReference = useRef(null);
  const grdata = useRef({});


  const handleResize = (isSidebarCollapsed) => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    setBoxSize({
      width: (window.innerWidth - 300) + (isSidebarCollapsed ? 180 : 0),
      height: window.innerHeight * 0.58,
    });

    if (chartReference.current) {
      chartReference.current.resize();
    }
  };

  useEffect(() => {
    const initializeCentrifuge = async () => {
      const token = await getTokenCentrifugo();
      if (!token) {
        console.error("Token is required to connect to Centrifuge.");
        return;
      }

      // Initialize Centrifuge with token
      const centrifugeClient = new Centrifuge(CENTRIFUGE_URL, {
        token,
      });

      centrifugeClient.connect();
      setCentrifuge(centrifugeClient);
    };

    initializeCentrifuge();

    return () => {
      if (centrifuge) {
        centrifuge.disconnect();
      }
    };
  }, []);

  const id = props.match.params.id;

  useEffect(() => {
    if (!centrifuge || !id) return;

    if (subscription) {
      subscription.unsubscribe();
      subscription.removeAllListeners();
      centrifuge.removeSubscription(subscription);
    }

    const channel = `channel${id}`;
    const newSubscription = centrifuge.newSubscription(channel);

    newSubscription.on("publication", handleMessage);
    setSubscription(newSubscription);

    fetchUnits(id);
    fetchData(id, state.date, newSubscription);

    return () => {
      newSubscription.unsubscribe();
      centrifuge.removeSubscription(newSubscription);
    };
  }, [centrifuge, id]);


  useEffect(() => {
    if (id !== state.pid) {
      setState((prevState) => ({
        ...prevState,
        pid: id,
      }));
    }
  }, [id]);

  useEffect(() => {
    // Check initial sidebar state on first load
    const isCollapsedOnLoad = document.body.classList.contains('sidebar-collapse');
    setSidebarCollapsed(isCollapsedOnLoad);

    handleResize(isCollapsedOnLoad);

    // Listen for window resize events
    const onResize = () => {
      handleResize(sidebarCollapsed);
    };

    window.addEventListener('resize', onResize);

    // Observe sidebar collapse changes (AdminLTE usually toggles 'sidebar-collapse' class on body)
    const observer = new MutationObserver(() => {
      const isCollapsed = document.body.classList.contains('sidebar-collapse');
      setSidebarCollapsed(isCollapsed);
      handleResize(isCollapsed);
    });

    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    return () => {
      window.removeEventListener('resize', onResize);
      observer.disconnect();
    };
  }, [sidebarCollapsed]);

  useEffect(() => {
    if (!isLoading && !permission) {
      logout();
      setUser(undefined);
      navigate("/signin");
    }
  }, [permission, isLoading]);


  const toggleConfirmDialog = () => {
    setState((prevState) => ({
      ...prevState,
      showConfirmDialogParent: !prevState.showConfirmDialogParent,
    }));
  };

  const showResult = () => {
    setState((prevState) => ({
      ...prevState,
      showResult: true,
    }));
  };

  const onHideUdgForm = () => {
    setState((prevState) => ({
      ...prevState,
      showModalUdgForm: false,
      showResult: false,
    }));
  };

  

  const handleMessage = (ctx) => {
    if (typeof ctx.data?.reload_client !== 'undefined') window.location.reload();
    charts_update_rt_data(ctx);
    updateTemperature(ctx);
    charts_update_plan_data(ctx);
  };

  const charts_update_plan_data = (ctx) => {
    if (typeof ctx.data?.plan === 'undefined') return;

    const chart = chartReference.current;
    chart.data.datasets[0].data = ctx.data.plan.udgk;
    chart.data.datasets[1].data = ctx.data.plan.udg;
    chart.data.datasets[3].data = ctx.data.plan.pbr;
    chart.update();
  };

  const charts_update_rt_data = (ctx) => {
    if (typeof ctx.data?.fact?.prod === 'undefined') return;

    grdata.current.rt_prev = grdata.current.rt;
    grdata.current.rt = ctx.data;

    const dt_prev = grdata.current?.rt_prev?.dt;
    const dt = grdata.current?.rt?.dt;

    if (typeof dt_prev !== 'undefined' && dt_prev !== dt) {
      setState((prevState) => ({
        ...prevState,
        date: dt,
      }));
      fetchData(state.pid, dt, subscription);
      return;
    }

    const chart = chartReference.current;
    const chart3min = areaChart3MinReference.current;
    const chartGou = areaChartGouReference.current;

    on_update_udg_callback(chart, grdata.current.rt, grdata.current.station);
    on_update_3min_callback(chart3min, grdata.current.rt, grdata.current.station);
    on_update_gou_callback(chartGou, grdata.current.rt, grdata.current.station, "y");

    chart.update();
    chart3min.update();
    chartGou.update();
  };

  const updateTemperature = (ctx) => {
    if (typeof ctx.data?.fact?.temp === 'undefined') return;

    setState((prevState) => ({
      ...prevState,
      temperatureValue: ctx.data.fact.temp,
    }));
  };

  const getTokenCentrifugo = async () => {
    try {
      const response = await fetchWithAuth(`${API}/centrifugo`);
      const responseData = await response.json();
      return responseData.token;
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  const fetchData = async (pid, date, newSubscription) => {
    try {
      newSubscription.unsubscribe();
      const dateStr = moment(date).format("YYYY-MM-DD");
      const response = await fetchWithAuth(`${API}/enery-stats-khps?pid=${pid}&date=${dateStr}`);
      const responseData = await response.json();
      drawStats(responseData, newSubscription);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchUnits = async (pid) => {
    try {
      const response = await fetchWithAuth(`${API}/units?filters[id][$eq]=${pid}`);
      const responseData = await response.json();
      setState((prevState) => ({
        ...prevState,
        title: `Блок_${responseData.data[0].attributes.name}`,
      }));
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  const drawStats = (data, newSubscription) => {
    const station = (grdata.current.station = data.station);
    const bucket = data.current_stats;

    energyView(bucket);

    if (station.id > 0) {
      setState((prevState) => ({
        ...prevState,
        responseDate: data.response_date,
        requestDate: data.request_date,
      }));
    }

    const chart = chartReference.current;
    if (data.current_date === data.request_date) {
      const xMin = new Date();
      xMin.setHours(0, 0, 0, 0);

      const xMax = new Date();
      xMax.setHours(new Date().getHours(), 0, 0, 0);

      chart.options.plugins.annotation.annotations.pbr = {
        type: 'box',
        backgroundColor: '#8a8a8a11',
        xMin: moment(xMin).format("YYYY-MM-DD HH:mm:ss"),
        xMax: moment(xMax).format("YYYY-MM-DD HH:mm:ss"),
      };
      newSubscription.subscribe();
    } else {
      newSubscription.unsubscribe();
      chart.options.plugins.annotation.annotations.pbr = null;
    }
  };

  const handleResetZoom = (event) => {
    if (chartReference.current && event.button === 0) {
      chartReference.current.options.scales.x.time.unit = 'hour';
      chartReference.current.resetZoom();
    }
  };

  const energyView = (bucket) => {
    let udgk = [],
      facts = [],
      pbrs = [],
      udg = [],
      rt3mins = [],
      rt3mins_labels = [];


    let gou_mode = 'y';

    let maxv = -1000000000, minv = 1000000000, mid3min = 90;

    pbrs = bucket.pbr;
    udgk = bucket.udgk;
    udg = bucket.udg;

    for (const k in bucket.pbr) {

      facts.push({
        x: k,
        y: bucket.rt[k]
      });

      if (bucket.pbr[k] < minv) {
        minv = bucket.pbr[k];
      }

      if (bucket.pbr[k] > maxv) {
        maxv = bucket.pbr[k];
      }

      if (bucket.udgk[k] < minv) {
        minv = bucket.udgk[k];
      }

      if (bucket.udgk[k] > maxv) {
        maxv = bucket.udgk[k];
      }

      if (bucket.udg[k] < minv) {
        minv = bucket.udg[k];
      }

      if (bucket.udg[k] > maxv) {
        maxv = bucket.udg[k];
      }

    }

    var max3min = -1000000000, min3min = 1000000000;

    var idx = 0;

    for (const k in bucket.rt3min) {

      rt3mins[idx] = bucket.rt3min[k];
      var minl = k.substring(6, 8);
      var mminl = k.substring(7, 8);
      var hminl = k.substring(0, 5);
      rt3mins_labels[idx] = minl === '00' ? hminl : (mminl === '0' ? minl : '');

      if (bucket.rt3min[k] < min3min) {
        min3min = bucket.rt3min[k];
      }

      if (bucket.rt3min[k] > max3min) {
        max3min = bucket.rt3min[k];
      }

      idx++;
    }

    mid3min = Math.round((max3min + min3min) / 2);


    const areaChart3Min = areaChart3MinReference.current;
    areaChart3Min.data = {
      labels: rt3mins_labels,
      datasets: [
        {
          label: 'Факт',
          data: rt3mins,
          fill: true,
          xAxisID: 'x',
          tension: 0.5,
          borderColor: '#00000044',
          backgroundColor: '#00000044',
          hoverBackgroundColor: '#00000044',
          borderWidth: 0,
          categoryPercentage: 1.1,
          spanGaps: true
        }
      ]
    }
    areaChart3Min.options = get_config_3min(mid3min);
    areaChart3Min.update();

    const areaChartGou = areaChartGouReference.current;
    areaChartGou.data = {
      labels: [''],
      datasets: [
        {
          label: 'Факт',
          axis: 'y',
          data: [0],
          fill: false,
          borderColor: '#00770099',
          backgroundColor: '#00770099',
        },
        {
          label: '2',
          axis: 'y',
          data: [1000],
          fill: false,
          borderColor: '#8a8a8a33',
          backgroundColor: '#8a8a8a33',
        },
      ]
    }
    areaChartGou.options = get_config_gou(gou_mode, minv, maxv);
    areaChartGou.update();

    const chart = chartReference.current;
    chart.data = {
      datasets: [
        {
          'label': 'УДГК',
          'data': udgk,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#336699',
          borderWidth: 2
        },
        {
          'label': 'УДГ',
          'data': udg,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#0099ff',
          borderWidth: 2
        },
        {
          'label': 'Факт',
          'data': facts,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#ff3300',
          borderWidth: 2,
          spanGaps: true
        },
        {
          'label': 'ПБР',
          'data': pbrs,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#ff9900',
          borderWidth: 2
        },
      ]
    };
    chart.options = get_config_udg(minv, maxv);
    chart.update();
  }

  return (
    <Content homeRoute="/" title={state.title} subTitle="">
      <ModalDialog
        title={`${state.title} :: Часовое отклонение за ${state.date}`}
        show={state.showModalDeviationLog}
        onHide={() => { setState((prevState) => ({ ...prevState, showModalDeviationLog: false, })); }}
      >
        <DeviationLog unitId={state.pid} period={state.date} />
      </ModalDialog>
      <ModalDialog
        title={`${state.title} :: Лог комманд УДГК за ${state.date}`}
        show={state.showModalUdgkLog}
        onHide={() => { setState((prevState) => ({ ...prevState, showModalUdgkLog: false, })); }}
      >
        <UdgkLog unitId={state.pid} period={state.date} />
      </ModalDialog>
      <ModalDialog
        modalSize="lg"
        title={`Регистрация команд УДГ: ${state.title}`}
        show={state.showModalUdgForm}
        onHide={onHideUdgForm}
        backdrop={true}
        modalFooter={state.showResult ?
          <div>
            <Button className="center-block" text="Закрыть окно" type="info" onClick={onHideUdgForm} />
          </div>
          :

          <div>
            <Button text="Отменить" onClick={onHideUdgForm} pullLeft />
            <Button text="Отправить" onClick={toggleConfirmDialog} type="info" pullRight />
          </div>
        }

      >
        <UdgForm unitId={state.pid} onHideConfirmDialog={toggleConfirmDialog} showResult={showResult} showConfirmDialog={state.showConfirmDialogParent} udgUnitTitle="Регистрация команд УДГ : Краснодарская ТЭЦ" />
      </ModalDialog>
      <ModalDialog
        modalSize="lg"
        title={`Относительная и абсолютная корректировка УДГК: ${state.title} `}
        backdrop={true}
        show={state.showModalUdgkForm}
        onHide={() => { setState((prevState) => ({ ...prevState, showModalUdgkForm: false, })); }}
      >
        <UdgkForm unitId={state.pid} />
      </ModalDialog>
      <ModalDialog
        title={`${state.title} :: Лог комманд УДГ за ${state.date}`}
        show={state.showModalUdgLog}
        onHide={() => { setState((prevState) => ({ ...prevState, showModalUdgLog: false, })); }}
      >

        <UdgLog unitId={state.pid} period={state.date} />
      </ModalDialog>

      <Row>
        <Col xs={12}>
          <div className="box">
            <div className="box-header">
              <h2 className="page-header">{"Выгрузка сформирована " + state.responseDate + " по дате " + state.requestDate}</h2>
              <Row>
                <Col md={12}>
                  <div className="form-horizontal">
                    <Col md={2}>
                      <Datepicker
                        label="Дата"
                        className="datepicker"
                        labelPosition="left"
                        iconLeft="fas-calendar"
                        format="YYYY-MM-DD"
                        inputIconPosition="before"
                        dateProps={{ "isOutsideRange": () => false, "numberOfMonths": 1 }}
                        value={state.date}
                        onChange={(event) => {
                          const { target: { value: value } } = event;
                          setState((prevState) => ({ ...prevState, date: value, }));
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Button type="success" icon="fas-play" text="Показать данные" onClick={() => fetchData(state.pid, state.date, subscription)} />
                    </Col>
                    <Col md={8}>
                      <ButtonToolbar className="pull-right">
                        <div className="btn bg-purple"> <span> {state.temperatureValue}</span> {" °C"} </div>
                        {!isLoading && hasPermission(permission, 'api::deviation-log', 'deviation-log', 'getLog') &&
                          <div className="btn-group">
                            <Button type="danger" text="Отклонения" >
                              <a onClick={() => { setState((prevState) => ({ ...prevState, showModalDeviationLog: true, })); }} href="#">Почасовые</a>
                            </Button>
                          </div>
                        }
                        {!isLoading && hasPermission(permission, 'api::udgk-form', 'udgk-form', 'insertCommand') &&
                          <div className="btn-group">
                            <Button type="primary" text="Регистрация команд" >
                              <a onClick={() => { setState((prevState) => ({ ...prevState, showModalUdgForm: true, })); }} href="#">УДГ</a>
                              <a onClick={() => { setState((prevState) => ({ ...prevState, showModalUdgkForm: true, })); }} href="#">УДГК</a>
                            </Button>
                          </div>
                        }
                        {!isLoading && hasPermission(permission, 'api::udg-log', 'udg-log', 'getLog') &&
                          <div className="btn-group">
                            <Button type="info" text="Лог команд" >
                              <a onClick={() => { setState((prevState) => ({ ...prevState, showModalUdgLog: true, })); }} href="#">УДГ</a>
                              <a onClick={() => { setState((prevState) => ({ ...prevState, showModalUdgkLog: true, })); }} href="#">УДГК</a>
                            </Button>
                          </div>
                        }
                      </ButtonToolbar>
                    </Col>
                  </div>
                </Col>
                <Col md={8}></Col>
              </Row>
              <div className="chart box with-border">
                <ResizableBox resizeHandles={['se']} width={boxSize.width} height={boxSize.height} minConstraints={[360, 360]} style={{ paddingBottom: '30px' }}>
                  <Line options={{ maintainAspectRatio: false }} onDoubleClick={handleResetZoom} style={{ height: '100%', width: '99%', margin: 'auto' }} ref={chartReference} data={state.chart} />
                </ResizableBox>
                <Row>
                  <Col md={8}> <div className="chart" style={{ height: '240px', width: '100%', margin: 'auto', position: 'relative' }}>
                    <Bar style={{ height: '240px', width: '100%', margin: 'auto', position: 'relative' }} ref={areaChart3MinReference} data={state.areaChart3Min}
                      options={state.areaChart3MinOption} /></div> </Col>
                  <Col md={4}>  <div className="chart" style={{ width: '100%', margin: 'auto', position: 'relative' }}> <Bar style={{ height: '224px', width: '100%' }} ref={areaChartGouReference} data={state.areaChartGou}
                  /></div> </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default Main;

import React from 'react';
import { FUXA_URL } from '../../constant'

const FuxaView = () => {
  return (
    <div style={{ width: '100%', height: '100%', minHeight: 600, border: 'none' }}>
      <iframe
        src={FUXA_URL}
        style={{ width: '100%', height: '100%',minHeight: 600, border: 'none' }}
        title="FUXA Main View"
      />
    </div>
  );
};

export default FuxaView;
import {
  Content, Row, Box, SimpleTable, Col, ProgressBar, Badge, Label, Inputs, Button,
} from 'adminlte-2-react';
import React, { useEffect, useState } from "react";
import { API } from "../../constant";
import { fetchWithAuth } from "../../api/fetchWithAuth"
import moment from 'moment';

const columns = [
      { title: 'Дата создания уведомления', data: 'date' },
      { title: 'Текст уведомления', data: 'message' },
];

function Notification() {
  const [notification, setNotification] = useState([]);

  const fetchNotification = async () => {
    try {
     
      const response = await fetchWithAuth(`${API}/notifications?sort=createdAt:DESC`);
      const data = await response.json();
      if (typeof data !== 'undefined' && data.data.length > 0) {
        debugger;
        setNotification(data.data.map(item=> ({
          date: moment(item.attributes.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          message: item.attributes.message
        })));
      }
      else {
        setNotification([{date: 'уведомления отсутствуют'}]);
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  return (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        <SimpleTable
                  hover
                  condensed
                  border
                  striped
                  columns={columns}
                  data={notification}
                />
      </div>
  );
}

export default Notification;
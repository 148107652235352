import { Route, Navigate, useNavigate, Routes } from "react-router-dom";
import Main from "./pages/Main/Main";
import UnitForm from "./pages/Unit/UnitForm";
import Units from "./pages/Unit/Units";
import TeleSignals from "./pages/TeleSignal/TeleSignals";
import Protocols from "./pages/Protocol/Protocols";
import TeleMeasurements from "./pages/TeleMeasurement/TeleMeasurements";
import TeleMeasurementForm from "./pages/TeleMeasurement/TeleMeasurementForm";
import ProtocolForm from "./pages/Protocol/ProtocolForm";
import TeleSignalForm from "./pages/TeleSignal/TeleSignalForm";
import FuxaView from "./pages/Fuxa/FuxaView";
import ModalDialog from "./components/Modal/ModalDialog"
import Notification from "./components/Modal/Notification"


import AdminLTE, {
  Navbar, Sidebar
} from 'adminlte-2-react';
import SignIn from "./pages/SignIn/SignIn";
import AuthCallback from "./pages/AuthCallback/AuthCallback";
import { useAuthContext } from "./context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isTokenExpired, hasPermission } from "./helpers";
import React, { useEffect, useState, useContext } from "react";
import { API } from "./constant";
import { fetchWithAuth } from "./api/fetchWithAuth"
import moment from 'moment';
import ControlSidebar from "./components/Menu/ControlSidebar"
import './App.css';
import { useSocket } from './context/SocketContext';

const { Header, Item } = Sidebar;


const App = () => {
  const [menuItems, setMenuItems] = useState([]);
  const navigate = useNavigate();
  const audio = new Audio("/positive-notification.mp3");

  const [showModalNotification, setShowModalNotification] = useState(false);

  const { user, permission, setUser, logout, token, isLoading } = useAuthContext();

  const socket = useSocket();

  const handleLogout = () => {
    logout();
    setUser(null);
    navigate("/signin");
  };


  const handleReadNotification = async (notificationId) => {
    const body = {
      data: {
        user: user,
        notification: notificationId
      }
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    };
    try {
      const response = await fetchWithAuth(`${API}/user-notifications`, requestOptions);
      if (response.ok) {
        setNotifications(prev => prev.filter(({ id }) => id !== notificationId));
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('notification:create', (data) => {
        console.log("Notification received:", data);
        const notification = { id: data.data.id, text: data.data.attributes.message, };
        setNotifications((prev) => [notification, ...prev]);
        playNotificationSound();
      });

      return () => {
        socket.off("notification:create");
      };
    }
  }, [socket]);

  const playNotificationSound = () => {
    audio.play().catch((error) => {
      console.error("Error playing notification sound:", error);
    });
  };

  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = async () => {
    try {
      if (!permission || !hasPermission(permission, 'api::notification', 'notification', 'create')) {
        return;
      }

      const response = await fetchWithAuth(`${API}/notification/unread`)
      const data = await response.json();
      const notification = data.map(item => ({
        id: item.id,
        text: item.message,
        createdAt: moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      }))
      setNotifications(notification);

    } catch (error) {
      console.error(error);
    }
  };

  const fetchMenu = async () => {
    try {
      if (token == null) {
        setMenuItems([]);
        return;
      }

      const response = await fetchWithAuth(`${API}/units`)
      const data = await response.json();
      const menu = data.data.map(item => ({
        id: item.id,
        text: `Блок_${item.attributes.name}`,
        createdAt: moment(item.attributes.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      }))

      menu.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
      setMenuItems(menu);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token && isTokenExpired(token)) {
      handleLogout();
    }
    fetchMenu();


  }, [token]);

  const [controlSidebarOpen, setControlSidebarOpen] = useState(false);

  const toggleControlSidebar = () => {
    setControlSidebarOpen(!controlSidebarOpen);
  };

  useEffect(() => {
    if (!isLoading && !user && !permission) {
      handleLogout();
    }
    fetchNotifications();
  }, [token, permission, user, isLoading]);

  return (
    <Routes>
      <Route path="*" element={token ? (
        <AdminLTE title={[<img
          key="logo"
          src="/logo-white.svg" /* Replace with your logo path */
          alt="Logo"
          className="filter-white"
          style={{ height: '40px', marginRight: '10px' }}
        />, "ЕАЕ:Платформа"]} titleShort={[<img
          key="logo"
          src="/logo-white.svg" /* Replace with your logo path */
          alt="Logo"
          className="filter-white"
          style={{ height: '40px', marginRight: '10px' }}
        />]} browserTitle="ЕАЕ: Платформа" theme="red-custom">
          <Sidebar.Core>
            <Header text="ОСНОВНЫЕ ПОКАЗАТЕЛИ" />
            {menuItems.map(p => <Item icon="fa-book" text={p.text} to={`/main/${p.id}`} />)}
            <Header text="тест" />
            <Item icon="fa-book" text={'Схема'} to={`/fuxa`} />
          </Sidebar.Core>
          <Navbar.Core>
            <ModalDialog
              title={`Уведомления`}
              show={showModalNotification}
              onHide={() => { setShowModalNotification(false); }}

            >
              <Notification />
            </ModalDialog>
            {isLoading ? <></> : hasPermission(permission, 'api::notification', 'notification', 'create') &&
              <Navbar.Entry
                icon="fas-bell"
                className={notifications.length !== 0 ? "notifications-menu" : "dropdown-sidebar"}
                labelType="warning"
                headerText={notifications.length !== 0 ? "У вас #value# новых уведомлений" : "Нет новых уведомлений"}
                onFooterClick={() => { setShowModalNotification(true) }}
                footerText="Посмотреть все уведомления"
              >
                {notifications.map(p => <Navbar.NotificationItem onClick={() => handleReadNotification(p.id)} icon="fa-info" iconColor="blue" text={p.text} />)}
              </Navbar.Entry>
            }
            <li>
              <a onClick={handleLogout}>
                {isLoading ? <>Loading...</> : <span class="hidden-xs">{user.name}</span>}
                <FontAwesomeIcon
                  icon={["fas", "sign-out-alt"]}
                />
              </a>
            </li>
            {isLoading ? <></> : hasPermission(permission, 'api::unit', 'unit', 'create') && <Navbar.Entry className="dropdown-sidebar" onClick={toggleControlSidebar}
              icon="fas-cogs"
            >

              <ControlSidebar isSidebarOpen={controlSidebarOpen}>
              </ControlSidebar>

            </Navbar.Entry>
            }
          </Navbar.Core>
          <Main path="/main/:id" exact />
          <UnitForm updateMenu={fetchMenu} path="/unit/new" exact />
          <UnitForm updateMenu={fetchMenu} path="/unit/edit/:id" />

          <Protocols path="/protocols/" exact />
          <TeleSignals path="/telesignals/" exact />
          <Units updateMenu={fetchMenu} path="/units/" exact />
          <ProtocolForm path="/protocol/new" />
          <ProtocolForm path="/protocol/edit/:id" />
          <TeleMeasurementForm path="/telemeasurement/new" />
          <TeleMeasurementForm path="/telemeasurement/edit/:id" />
          <TeleSignalForm path="/telesignal/new" />
          <TeleSignalForm path="/telesignal/edit/:id" />
          <TeleMeasurements path="/telemeasurements/" exact />
          <FuxaView path="/fuxa/" exact />
          
        </AdminLTE>) : <Navigate to="/signin" />
      }>
      </Route>
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route path="/signin" element={<SignIn />} />
    </Routes>
  );
};


export default App;
